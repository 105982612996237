import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Layout from "../../components/Layout";
import { StaticImage } from "gatsby-plugin-image";
import ArticlesGrid from "../../components/articles-grid";
import Seo from "../../components/Seo";
import Headings from "../../components/headings";

/*
~~STRAPI~~
~~1. Crear la estructura correcta en strapi para el contenido de los blogs~~
~~2. Crear types para etiquetas y categorías (y que estas aparezcan y se liguen en GraphQL)
~~3. Crear estructura de SEO
~~4. Crear programáticamente los post en la URL base "/url" ¿O de la categoría? Y esto significaría hacer un 301 redirect~~

~~Gatsby GrapQL~~
~~4. Crear programaticamente las páginas de categoría~~
~~5. Crear programaticamente las páginas de tags~~

_____SEO_____
6. Hacer un robot.txt
7. Hacer un sitemap
8. Hacer pruebas de metaGraph

_____BLOG_____
9. Hacer sidebar
*/

const IndexPage = () => {
  const { allStrapiArticle, strapiGlobal } = useStaticQuery(graphql`
    query {
      allStrapiArticle(sort: { order: DESC, fields: fecha }) {
        nodes {
          ...ArticleCard
        }
      }
      strapiGlobal {
        siteName
        siteDescription
      }
    }
  `);

  return (
    <Layout>
      <Seo
        seo={{
          metaTitle: "Blog",
          metaDescription:
            "Encuentra recetas de Cocteles y Bebidas Preparadas con Boone´s",
        }}
      />
      <header className="">
        <StaticImage
          src="../../assets/images/blog/banner-blog.png"
          alt="Blog Boones"
          placeholder="tracedSVG"
          layout="fullWidth"
        />
      </header>
      {/*} <Headings
        title={strapiGlobal.siteName}
        description={strapiGlobal.siteDescription}
  />*/}
      <main>
        <ArticlesGrid articles={allStrapiArticle.nodes} />
      </main>
    </Layout>
  );
};

export default IndexPage;
